import { useRouter } from 'next/router'
import configSV from '@config/seo_sv.json'
import configEN from '@config/seo_en.json'
import configDA from '@config/seo_da.json'

export type localeType = 'en' | 'sv' | 'da'
export type languageType = 'eng' | 'swe' | 'dan'
export type languageUpperCaseType = 'Eng' | 'Swe' | 'Dan'
export type countryCodeType = 'SE' | 'DK'
export type currencyCodeType = 'DKK' | 'SEK'
/*
  The empty string is because en is the Next.js default locale
  So we use this for creating SEO links and etc
*/
export type localePrefixType = '/sv' | '/da' | ''

export type localeReturnObject = {
  locale: localeType
  bcp47locale: string
  language: languageType
  languageUpper: languageUpperCaseType
  countryCode: countryCodeType
  currencyCode: currencyCodeType
  localePrefix: localePrefixType
  baseUrlWithLocale: string
  config: object
  countryName: string
  path: string
}

export const baseUrl = `https://${
  process.env.NEXT_PUBLIC_VERCEL_URL || 'localhost:3000'
}`

export const fallbackLocaleObject: localeReturnObject = {
  locale: 'en',
  bcp47locale: 'en-GB',
  language: 'eng',
  languageUpper: 'Eng',
  // Note that we use the Swedish shop and prices for the international site
  countryCode: 'SE',
  currencyCode: 'SEK',
  localePrefix: '',
  baseUrlWithLocale: baseUrl,
  config: configEN,
  countryName: 'International',
  path: 'store.sesamy.com',
}

const localeObjects: localeReturnObject[] = [
  {
    locale: 'sv',
    bcp47locale: 'sv-SE',
    language: 'swe',
    languageUpper: 'Swe',
    countryCode: 'SE',
    currencyCode: 'SEK',
    localePrefix: '/sv',
    baseUrlWithLocale: `${baseUrl}/sv`,
    config: configSV,
    countryName: 'Sweden',
    path: 'store.sesamy.com/sv',
  },
  {
    locale: 'da',
    bcp47locale: 'da-DK',
    language: 'dan',
    languageUpper: 'Dan',
    countryCode: 'DK',
    currencyCode: 'DKK',
    localePrefix: '/da',
    baseUrlWithLocale: `${baseUrl}/da`,
    config: configDA,
    countryName: 'Denmark',
    path: 'store.sesamy.com/da',
  },
]

export const getLanguageObjectFromLocale = (
  locale?: string,
): localeReturnObject => {
  const localeObject = localeObjects.find(
    (localeObj) => localeObj.locale === locale,
  )

  return localeObject || fallbackLocaleObject
}

/**
 * Returns a language object by country code. If the country code passed is not in the available country codes list, we
 * return the International locale object as a default.
 */
export const getLanguageObjectFromCountryCode = (countryCode?: string) => {
  const localeObject = localeObjects.find(
    (localeObj) => localeObj.countryCode === countryCode,
  )

  return localeObject || fallbackLocaleObject
}

export const getAllLocales = () => {
  return [fallbackLocaleObject, ...localeObjects]
}

export const useLocale = (): localeReturnObject => {
  const { locale = 'en' } = useRouter()

  return getLanguageObjectFromLocale(locale)
}
