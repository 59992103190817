import '@assets/font-definitions.css'
import '@assets/main.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { FC } from 'react'
import type { AppProps } from 'next/app'
import { Head } from '@components/common'
import { User } from '@components/auth'
import Affiliate from '@components/affiliate/Affiliate'
import { AudioPlayer } from '@components/product'
import { appWithTranslation } from 'next-i18next'
import { CartProvider } from '@contexts/CartContext'
import Router from 'next/router'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '@components/common/ErrorFallback'
import { datadogLogs } from '@datadog/browser-logs'
import { isCrawler } from '@lib/tracking'
import { appWithModal } from '@contexts/ModalContext'
import { AuthTokenProvider } from '@contexts/auth-token-context'
import { Auth0Provider } from '@auth0/auth0-react'
import { baseRedirectUri } from '@lib/auth'
import { IntercomProvider } from '@lib/intercom/provider'

const Noop: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <>{children}</>
)

Router.events.on('routeChangeComplete', (url) => {
  window?.analytics?.page(url)
})

const ERROR_IGNORE_LIST = [
  'ResizeObserver loop limit exceeded',
  'Minified React error',
]

if (!process.env.NEXT_PUBLIC_SESAMY_TOKEN_URL) {
  throw new Error('Define NEXT_PUBLIC_SESAMY_TOKEN_URL')
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  datadogLogs.init({
    clientToken: 'pub53c535e7f8bf9979dd4894cb2c95622b',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: 'commerce-prod',
    env: 'production',
    version: '1.0',
    beforeSend: ({ error, message }) => {
      if (isCrawler(navigator.userAgent)) {
        return false
      }

      if (
        error &&
        ERROR_IGNORE_LIST.filter((i) => message.includes(i)).length > 0
      ) {
        return false
      }
    },
  })
}

if (!process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL) {
  throw new Error('Define NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL')
}
if (!process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID) {
  throw new Error('Define NEXT_PUBLIC_AUTH0_CLIENT_ID')
}

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head />
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(appWithModal(App))
