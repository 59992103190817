import React, { createContext, ReactNode, useContext, useState } from 'react'
import dynamic from 'next/dynamic'
import type { AppProps } from 'next/app'

const Modal = dynamic(() => import('@components/ui/Modal/Modal'), {
  ssr: false,
})

type ContentType = ReactNode

interface State {
  content: ContentType
  openModal: (
    content: ContentType,
    maxWidth?: 'small' | 'large',
    hideCloseBtn?: boolean,
  ) => void
  closeModal: () => void
}

interface Props {
  children: ReactNode
}

const initialState: State = {
  content: null,
  openModal() {},
  closeModal() {},
}

const ModalContext = createContext<State>(initialState)

const ModalProvider = ({ children }: Props) => {
  const [content, setContent] = useState<ContentType>(null)
  const [maxWidth, setMaxWidth] = useState<'small' | 'large'>('large')
  const [hideCloseBtn, setHideCloseBtn] = useState<boolean>(false)

  /**
   * Add a new modal content
   */
  function openModal(
    content: ReactNode,
    maxWidth?: 'small' | 'large',
    hideCloseBtn?: boolean,
  ) {
    setContent(content)
    if (maxWidth) {
      setMaxWidth(maxWidth)
    }
    if (hideCloseBtn) {
      setHideCloseBtn(true)
    }
  }

  /**
   * Close the modal by removing its content
   */
  function closeModal() {
    setContent(null)
  }

  return (
    <ModalContext.Provider
      value={{
        content,
        openModal,
        closeModal,
      }}
    >
      {children}

      <Modal
        isOpen={content !== null}
        onClose={closeModal}
        maxWidth={maxWidth}
        hideCloseBtn={hideCloseBtn}
      >
        {content}
      </Modal>
    </ModalContext.Provider>
  )
}

function useModal() {
  const context = useContext(ModalContext)

  return {
    openModal: context.openModal,
    closeModal: context.closeModal,
  }
}

const appWithModal =
  (
    InnerComponent:
      | React.ComponentType<React.PropsWithChildren<AppProps>>
      | React.ElementType<AppProps>,
  ) =>
  // eslint-disable-next-line react/display-name
  (props: AppProps) => {
    return (
      <ModalProvider>
        <div>
          <InnerComponent {...props} />
        </div>
      </ModalProvider>
    )
  }

export { useModal, appWithModal }

export default ModalProvider
