import { FC, useState, useEffect } from 'react'
import NextHead from 'next/head'
import { DefaultSeo } from 'next-seo'

import Script from 'next/script'
import { useLocale } from '@lib/locales/utils'

const Head: FC<React.PropsWithChildren<unknown>> = () => {
  const [theme, setTheme] = useState('light')
  const { config } = useLocale()

  useEffect(() => {
    const getMql = () =>
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')
    const mql = getMql()
    const getBrowserTheme = () => {
      return mql && mql.matches ? 'dark' : 'light'
    }

    const setColorTheme = () => {
      const browserTheme = getBrowserTheme()
      if (browserTheme !== theme) {
        setTheme(browserTheme)
      }
    }

    setColorTheme()

    if (mql?.addEventListener) {
      mql && mql.addEventListener('change', setColorTheme)
      return () => {
        mql && mql.removeEventListener('change', setColorTheme)
      }
    }
  })

  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

  return (
    <>
      <DefaultSeo
        {...config}
        dangerouslySetAllPagesToNoIndex={!isProduction}
        dangerouslySetAllPagesToNoFollow={!isProduction}
      />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#000000"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicons/favicon-32x32-${
            theme === 'dark' ? 'light' : 'dark'
          }.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/favicons/favicon-16x16-${
            theme === 'dark' ? 'light' : 'dark'
          }.png`}
        />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <meta name="theme-color" content="#000000" />
      </NextHead>
    </>
  )
}

export default Head
